<template>
  <modal :show-modal="showModal">
    <template v-slot:header>
      <h4>{{ eula.title }}</h4>
    </template>
    <template v-slot:main-content>
      <div>
        <p v-html="render(eula.terms)"></p>
        <FormulateInput 
          v-model='acceptEula'
          type='checkbox'>
          <template v-slot:label>
            <label class='eula-checkbox-label'>
              I agree to the <a href='https://help.orbitalstack.com/terms/' target='_blank'>terms & conditions</a>
            </label>
          </template>
        </FormulateInput>
      </div>
    </template>
    <template v-slot:footer>
      <div class='accept-eula-button-container'>
        <b-button 
          :disabled="!acceptEula || acceptButtonClicked" 
          class='accept-eula-button' 
          @click='acceptClicked'
          variant='primary'>
          Accept EULA  
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'rwdi-widgets';
import md from '@/utilities/markdown-rendering.js';

export default {
  name: 'EulaModal',
  components: {
    Modal
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    eula: {
      required: true
    },
    submitAcceptedEula: {
      required: true
    }
  },
  data() {
    return {
      acceptEula: false,
      acceptButtonClicked: false
    };
  },
  methods: {
    render(markdown) {
      return md.render(markdown);
    },
    acceptClicked() {
      this.acceptButtonClicked = true;
      this.submitAcceptedEula();
    }
  }
};
</script>

<style scoped>
.eula-checkbox-label {
  margin-left: 0.313rem;
}

.accept-eula-button-container {
  overflow: auto;
}

.accept-eula-button {
  float: right;
}

.formulate-input {
  margin: 0;
  height: 1.875rem;
}
</style>